"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'VanFieldSelectPicker',
  model: {
    prop: 'selectValue'
  },
  props: {
    columns: {
      type: Array
    },
    selectValue: {
      type: String
    },
    inputAlign: {
      type: String,
      default: function _default() {
        return 'left';
      }
    }
  },
  data: function data() {
    return {
      show: false,
      result: this.selectValue
    };
  },
  methods: {
    onConfirm: function onConfirm(value) {
      this.result = value;
      this.show = !this.show;
    }
  },
  watch: {
    selectValue: function selectValue(newVal) {
      this.result = newVal;
    },
    result: function result(newVal) {
      this.$emit('input', newVal);
    }
  }
};
exports.default = _default2;