"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'loading',
  components: {},
  props: {
    title: {}
  },
  data: function data() {
    return {
      isShow: false
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  methods: {
    show: function show() {
      this.isShow = true;
    },
    hide: function hide() {
      this.isShow = false;
    }
  }
};
exports.default = _default;